import { Box } from "@mui/material"
import React from "react"
import Sidebar from "./Sidebar"

const Main = () => {
    return (
        <Box sx={{ display: "flex" }}>
            <Sidebar />
        </Box>
    )
}

export default Main
