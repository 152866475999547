import React from "react"
import { useKeycloak } from "@react-keycloak/web"
import { useIdleTimer } from "react-idle-timer"

import Main from "../components/Main"

const Home = () => {
    return <Main />
}

export default Home
